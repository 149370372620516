.card-base {
  @apply rounded-xl shadow text-darkest;
}

.card-gradient {
  @apply bg-gradient-to-b from-primary to-light;
}

.card-plain {
  @apply bg-light
}

.card-hoverable {
  @apply cursor-pointer transition duration-500 ease-out transform;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply transition duration-500 ease-out;
  }
}

.card-hoverable.card-gradient:hover {
  @apply bg-gradient-to-b from-primary to-dark
}

.card-hoverable.card-plain:hover {
  @apply bg-dark
}


@media(hover: hover) and (pointer: fine) {
  .card-hoverable:hover {
    @apply -translate-y-1 scale-105;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply text-lightest;
    }
  }
}

@media(hover: none) {

  .card-hoverable:hover,
  .card-hoverable:active {
    @apply bg-gradient-to-b from-primary to-dark duration-200;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply text-lightest;
    }
  }
}
